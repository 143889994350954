.auth_container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(33, 33, 33, 0.1);
  padding: 30px;
  width: 90%;
}
.form .avater {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.form h2 {
  margin-bottom: 30px;
  color: black;
  font-weight: 900;
  position: relative;
}
.form h2::before {
  content: "";
  width: 10%;
  height: 5px;
  background-color: #e33861;
  position: absolute;
  bottom: -10px;
}
.auth_btn {
  margin-left: auto;
  background-color: #e33861;
  margin-right: auto;
  border-radius: 30px;
  padding: 12px 40px;
  margin-top: 40px;
  box-shadow: 0px 30px 50px rgba(168, 22, 75, 0.15);
}
.auth_btn .loader div {
  border-color: white;
}
.auth_btn .loader div {
  border-top-color: #e33861;
}
.fpassword {
  color: #4d5959;
  font-weight: 600;
  text-align: right;
  display: block;
  font-size: 0.9em;
}
.form span {
  text-align: center;
  font-size: 0.9em;
  display: block;
  margin-top: 20px;
  color: #4d5959;
  font-weight: 600;
}
.form span a {
  color: #e33861;
}
.error-text {
  text-align: left !important;
  display: block !important;
  color: red !important;
  margin-top: 5px !important;
}
@media (min-width: 760px) {
  .form {
    max-width: 400px;
  }
  .form.register {
    max-width: 800px;
  }
}

@media (max-width: 760px) {
  .form.register {
    width: 100%;
    margin-bottom: 30px;
  }
}
