.iframe {
  width: 100%;
  height: 315px;
}
.iframe iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bn_ul {
  list-style: none;
}
.benefits {
  display: flex;
  padding: 80px;
}
.yt,
.ben {
  width: 50%;
}
.ben {
  padding-left: 6%;
}
@media (max-width: 760px) {
  .benefits {
    flex-direction: column;
    padding: 80px 0px;
  }
  .yt,
  .ben {
    width: 100%;
    padding-left: 0;
  }
  .yt{
    padding: 0 0px 20px;
    width: 90vw;
    margin: auto;
  }
  .ben{
    padding: 20px 40px 0;
  }
  .iframe {
    width: 100%;
  }
}
