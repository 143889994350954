.schemas {
  background-color: white;
  padding-top: 80px;
  padding-bottom: 80px;
}
.ech {
  text-align: center;
  display: block;
  margin: 30px 0px;
  color: #797979b5;
  font-weight: 600;
}
.schemas_list {
  display: flex;
  justify-content: space-between;
  /* align-items: ; */
}
.schema {
  background: #ffffff;
  border-radius: 40px;
  padding: 70px 60px;
  width: 30vw;
  max-width: 400px;
  margin-top: 50px;
  border: 1px solid #d9d9d9;
}
.schema:hover{
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
}
.schema ul,
.bn_ul {
  list-style: none;
}
.schema ul li,
.bn_ul li {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.schema ul li span,
.bn_ul li span {
  font-size: 0.85em;
  display: block;
  flex: 1;
  margin-left: 10px;
}
.agreements a {
  color: var(--txt) !important;
  text-decoration: underline;
}
.schema {
  color: #64607d;
}
.schema h2 {
  font-weight: 800;
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 20px;
  color: black;
  margin-top: 20px;
}
.schema p {
  text-align: center;
  line-height: 23px;
  font-size: 0.9em;
  margin-bottom: 30px;
}
.percentage {
  font-size: 4em;
  font-weight: 800;
  text-align: center;
  display: block;
  margin: 15px 0px;
  color: black;
  padding: 15px 0px;

  background: rgba(227, 56, 97, 0.08);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23DA2F72FF' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='50' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
  color: #e33861;
  font-weight: 900;
  position: relative;
  overflow: hidden;
}
.agreements {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.download {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin: 20px 0px;
  background: #fff2f2;
  border-radius: 30px;
  padding: 10px 15px;
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.download svg {
  margin-right: 10px;
}
.download span {
  color: #e33861;
  font-weight: 700;
}
.price {
  background-color: #e33861;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 800;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -80px;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
}
.schema_btn {
  background: #e33861;
  border-radius: 25px;
  padding: 10px 40px;
  color: white;
  font-weight: 600;
  border: none;
  outline: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.disabled {
  background: rgba(204, 204, 204, 0.58) !important;
}
.schema .ch span {
  font-size: 0.8em;
}
@media (max-width: 1100px) {
  .schemas_list {
    flex-wrap: wrap;
  }
  .schema {
    width: 40vw;
    padding: 30px;
  }
  .last {
    /* margin-top: 100px; */
  }
}
@media (max-width: 760px) {
  .schema {
    width: 100%;
    max-width: unset;
    margin-bottom: 50px;
  }
}

@media (min-width: 1000px) {
  .price {
    margin-top: -110px;
  }
}
.ooo {
  font-weight: 700;
}
.select_inv {
  width: 100%;
  margin-top: 15px;
  padding: 13px;
  cursor: pointer;
  background-color: #e33861;
  color: white;
  font-weight: 600;
  border: none;
  border-color: #e33861;
  outline: none;
  border-radius: 15px;
  display: block;
  text-align: center;
  font-size: 0.9em;
}
.title.cn {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
