.ch span {
  display: block;
  font-size: 0.9em;
  color: #535353;
  font-weight: 500;
}
.ch {
  margin-bottom: 15px;
  align-items: center;
}
.bx {
  cursor: pointer;
  margin-right: 20px;
  height: fit-content;
}
.bx svg {
  width: 15px;
}

.fcheck {
  margin-right: 30px;
}
.fcheck span {
  margin-bottom: 22px;
}
