.loader div {
  width: 50px;
  height: 50px;
  border: 6px solid var(--red);
  border-radius: 50%;
  animation: rotation 2s infinite linear;
  border-top-color: white;
}

.app_loader {
  width: 100vw;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
