.modalComponent {
  font-family: "Ubuntu", sans-serif;
  position: fixed;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  display: grid;
  place-items: center;
}

.modalContent {
  width: 90%;
  max-width: 420px;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
}

.close {
  width: fit-content;
  display: flex;
  margin-left: auto;
}

.close svg {
  cursor: pointer;
}

.modalComponent .button {
  display: block;
  margin: auto;
  border: none;
  border-radius: 22px;
  width: fit-content;
  font-weight: 600;
  background-color: #e33861;
}
