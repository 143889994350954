header {
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100vw;
  height: 60px;
  overflow: hidden !important;
  position: fixed;
  top: 0;
  z-index: 20;
  background-color: white;
}
.linkbgf {
  margin-top: 0;
}
/* .htopw {
  position: absolute;
}
.htopw svg {
  transform: scale(20) rotate(-122.61deg) translateY(22px) translateX(16px);
} */
header ul {
  list-style: none;
  margin-bottom: 10px;
  z-index: 1;
}
li {
  cursor: pointer;
}
header ul li {
  font-weight: 600;
  color: #4d5959;
  padding: 10px;
}
header ul li a {
  font-weight: 400;
  color: #4d5959;
  font-weight: 600;
}
header ul li.active a,
header ul li:hover a {
  color: var(--red);
  font-weight: 700;
}
.sht {
  position: absolute;
  /* width: 100%; */
  transform: rotate(-90deg);
  /* background-color: green; */
  left: 22%;
  z-index: 1;
  margin-bottom: 50px;
  /* background-color: green; */
}

@media (max-width: 760px) {
  .llap {
    margin-top: 5px;
    transform: scale(0.8);
    margin-left: -20 px;
    /* background-color: red; */
  }
  .menu {
    width: 30px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu div {
    width: 100%;
    height: 5px;
    background-color: #4d5959;
    margin-bottom: 3px;
  }
  .nav {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: -100vh;
    left: 0;
    background-color: white;
    z-index: 1000000000000;
    transition: 0.5s all ease-in-out;
  }
  .nav.opened {
    top: 60px;
  }
  .nav ul {
    flex-direction: column;
  }
  .nav ul li {
    padding: 10px;
  }
  .llap {
    margin-left: -20px;
  }
}
.nav ul li a {
  width: 100%;
  display: block;
}
.header-get-started {
  background-color: #f3f5f8;
}
.btn {
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  background-color: #ff5043;
  width: fit-content;
  padding: 10px 20px;
  color: white;
}
@media (min-width: 1000px) {
  header {
    padding-top: 2.2%;
    padding-bottom: 2.2%;
  }
}

@media (max-width: 760px) {
  header ul li {
    padding: 15px !important;
  }
  header {
    background-color: #f7f7f7;
    height: 70px;
  }
  .menu div {
    height: 4px;
    width: 80%;
    background-color: #000000;
  }
  .menu div:last-child {
    width: 60%;
    margin-left: auto;
    margin-right: 10%;
  }
}
