.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 75vh;
}
.hero p {
  text-align: center;
  margin: 15px 0px;
  color: #747582;
  line-height: 36px;
}
.hero h2 {
  font-size: 3em;
  text-align: center;
  color: #282c4b;
  font-weight: 700;
}
.hero h2 span {
  color: #e33861;
}
.btb_h {
  margin-top: 30px;
  background: #e33861;
  box-shadow: 0px 30px 50px rgba(168, 22, 75, 0.15);
  border-radius: 192px;
  padding: 15px 20px;

  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
}
@media (min-width: 1000px) {
  .hero {
    padding-left: 20%;
    padding-right: 20%;
  }
  .hero p {
    max-width: 800px;
  }
}

@media (max-width: 760px) {
  .hero {
    padding-top: 20%;
    padding-bottom: 10%;
    /* min-height: 80vh; */
  }
  .hero h2 {
    font-size: 2em;
    line-height: 40px;
  }
  .hero p {
    font-size: 0.9em;
    line-height: 22px;
  }
}
