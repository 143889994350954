.payment-status {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 60px auto 0;
	width: 350px;
	height: 70vh;
	font-family: 'Manrope';
	text-align: center;
}

.payment-status svg {
	width: 100px;
	height: 100px;
	margin-bottom: 20px;
}

.payment-status h2 {
	font-weight: 800;
	font-size: 24px;
	color: #5daf1d;
	margin-bottom: 30px;
}

.payment-status .fail {
	color: #ef0000;
}

.payment-status p {
	color: #727171;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 30px;
	line-height: 40px;
}

.payment-status button {
	width: 200px;
	height: 50px;
	background: #e33861;
	box-shadow: 5px 5px 10px rgba(168, 22, 75, 0.1);
	border-radius: 28px;
	color: white;
	font-weight: 700;
	font-size: 18px;
	border: none;
	font-family: 'Ubuntu';
	cursor: pointer;
}
