.flex_info {
  background-color: white;
}
.f_svg {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 30px;
}
.f_svg svg {
  width: 40px;
}
.f_card {
  width: 25%;
}
.f_card h3,
.f_card p {
  text-align: center;
}
.f_card p {
  font-size: 0.9em;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #797979;
}
.fxs {
  display: flex;
  margin: 50px 0px;
  flex-wrap: wrap;
}
.hin {
  text-align: center;
}
.hin span {
  font-size: 0.5em;
  color: #f57059;
}
.f_card h3 {
  font-size: 1.3em;
  font-weight: 700;
  margin-bottom: 7px;
}

@media (max-width: 760px) {
  .f_card {
    width: 100%;
    margin-bottom: 30px;
  }
}
