.itmlist {
  justify-content: space-between;
}
.svv {
  width: 48px;
  height: 48px;
  background-color: #e33861;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.search {
  display: flex;
  align-items: center;
}
.search .input {
  margin: unset;
  width: calc(100% - 50px);
}

@media (min-width: 760px) {
  .search {
    max-width: 400px;
  }
}
