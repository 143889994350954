.popcnt {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pupup {
  background-color: var(--bg);
  padding: 20px;
  width: 80%;
  max-width: 500px;
}
.pupup .ill svg {
  width: 80%;
  object-fit: contain;
}
.pupup .ill {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.pupup button,
.pppp {
  width: 80%;
  margin-left: 10%;
  margin-top: 10px;
  padding: 10px;
  background-color: #da2f72;
  color: white;
  border: none;
  border-color: #da2f72;
  font-weight: 600;
  cursor: pointer;
}
.popcnt h2 {
  text-align: center;
}
.pupup span {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.lkhg svg {
  transform: scale(0.7);
}
.lkhg .nat {
  margin-left: -10px !important;
}
